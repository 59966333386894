<template>
  <div class="container">
    <el-row type="flex" justify="start">
      <!-- <el-col :span="2" /> -->
      <el-col :span="20">
        <h1><b class="barraR">/</b>Página Não Encontrada</h1>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import { createItinerary } from "@/helpers/itineraries";
import { Timer } from "@element-plus/icons-vue";

export default {
  name: "NotFound",
  components: { Timer },
  props: {},
  computed: { ...mapGetters("auth", ["isLoggedIn", "currentUser", "token"]) },
  data() {
    return {
      loading: true,
      itineraries: [],
      loadingCreatingItinerary: false,
    };
  },
  async created() {
    const self = this;
    document.title = "Not Found";
  },
  async mounted() {},
  methods: {},
};
</script>

<style scoped>
.container {
  margin: 0 50px 0 50px;
}

.inputs {
  margin-top: 50px;
}

.tables {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: 50px 0 50px 0;
}

h1 {
  margin: 50px 0 50px 0;
}

.barraR {
  color: #0567ff;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  /* margin-left: 20px; */
}

.ibge-tooltip {
  position: absolute;
  border: 1px solid #f45197;
  border-radius: 20px;
  width: 10px;
  top: -20px;
  color: #f45197;
}
.ibge-tooltip:focus,
.ibge-tooltip:hover {
  color: #f45197;
  border-color: #f45197;
}

.ranking-stores-table,
.ranking-regionals-table,
.ranking-clusters-table {
  /* border-radius: 15px; */
  text-align: left !important;
}

.el-input,
.el-autocomplete {
  --el-border-color-base: #0567ff;
  --el-border-base: var(--el-border-width-base) var(--el-border-style-base) var(--el-border-color-base);
}
</style>

<style>
.el-popover.el-popper {
  font-family: "Roboto", sans-serif;
  width: 200px !important;
}
/* Font-family Calender e Search */
.el-autocomplete-suggestion li {
  font-family: "Roboto", sans-serif;
}
.el-picker-panel__body {
  font-family: "Roboto", sans-serif;
}
/* inputs */
.ranking-search {
  width: 100%;
}

.ranking-date-editor {
  border: 1px solid #0567ff !important;
}

/* tables */
.ranking-table {
  --el-table-border-color: #959595 !important;
}

.ranking-table .header .cell {
  color: #ffffff !important;
  width: 92% !important;
}

.ranking-table .cell {
  word-break: inherit !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  margin-left: 10px;
}

.ranking-table td.el-table__cell div {
  color: #2c2c2c !important;
}

.ranking-stores-table .header {
  background-color: #0567ff !important;
}
.ranking-regionals-table .header {
  background-color: #f45197 !important;
}
.ranking-clusters-table .header {
  background-color: #3e9a00 !important;
}
html::-webkit-scrollbar {
  width: 12px;
  background: rgb(250, 246, 240, 0.25);
}
html::-webkit-scrollbar-thumb {
  background-color: #cacaca;
  border-radius: 20px;
  border: 4px solid #fdfbf9;
}
</style>
